<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>{{ role_name }} Email Template</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Email Template
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar"></div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <div class="row">
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-on:keyup.enter="searchEmailTemplate"
                    @input="search.info = $event !== null ? $event : ''"
                    label="Lookup by Title"
                    v-model="search.info"
                    outlined
                    dense
                    clearable
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-select
                    v-model="search.roles"
                    :items="roles"
                    label="Roles"
                    item-text="text"
                    v-on:keyup.enter="searchEmailTemplate"
                    item-value="value"
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6" md="3">
                  <v-select
                    :items="status"
                    v-model="search.is_active"
                    label="Status"
                    item-text="name"
                    item-value="value"
                    outlined
                    v-on:keyup.enter="searchEmailTemplate"
                    dense
                    clearable
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-btn
                    :loading="isLoading"
                    @click="searchEmailTemplate"
                    color="primary"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon
                    >&nbsp; Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader type="table-thead" v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader v-if="isLoading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                  <th class="px-3">Title</th>
                  <th class="px-3">Subject</th>

                  <th class="px-3">Status</th>
                  <th class="px-3 text-center">Action</th>
                </thead>
                <tbody v-if="email_templates.length > 0">
                  <tr
                    v-for="(email_template, index) of email_templates"
                    :key="index"
                  >
                    <td class="px-2">
                      <a
                        class="text-dark-75 text-hover-primary mb-1"
                        @click="__edit(email_template.id)"
                        >{{ email_template.title }}
                      </a>
                    </td>
                    <td class="px-2">
                      <span
                        class="font-weight-bolder text-dark-75 text-hover-primary mb-1 font-size-lg"
                      >
                        {{
                          email_template.subject
                            ? email_template.subject
                            : "N/A"
                        }}
                      </span>
                    </td>
                    <td class="px-2">
                      <span
                        class="badge badge-success"
                        v-bind:class="{
                          'badge-success': email_template.is_active,
                          'badge-danger': !email_template.is_active
                        }"
                      >
                        {{ email_template.status_text }}
                      </span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-175px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a
                                @click="__edit(email_template.id)"
                                class="navi-link"
                              >
                                <span class="navi-icon">
                                  <v-icon color="blue darken-2"
                                    >fas fa-edit</v-icon
                                  >
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a
                                @click="clone(email_template)"
                                class="navi-link"
                              >
                                <span class="navi-icon">
                                  <v-icon color="orange darken-2"
                                    >fas fa-clone</v-icon
                                  >
                                </span>
                                <span class="navi-text">Customize</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="50" class="text-center">No data found</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import EmailTemplateService from "@/service/settings/email/EmailTemplateService";

const emailTemplate = new EmailTemplateService();

export default {
  name: "Index",
  components: {},
  data() {
    return {
      email_templates: [],
      page: null,
      total: null,
      perPage: null,
      search: {
        info: "",
        is_active: "",
        roles: ""
      },
      isLoading: false,
      roles: [],
      cloneDialog: false,
      scores: [],
      email_template: {
        id: "",
        title: "",
        role: "",
        type: "",
        subject: "",
        description: "",
        accepted_inputs: "",
        is_active: true
      },
      status: [
        { name: "Active", value: 1 },
        { name: "Inactive", value: 0 }
      ],
      cloneLoading: false,
      currentUser: {}
    };
  },
  computed: {
    role() {
      return this.$route.params.role;
    },
    role_name() {
      let role = this.$route.params.role;
      role = role.charAt(0).toUpperCase() + role.slice(1);
      return role.replace("_", " ");
    }
  },
  mounted() {
    this.search.roles = this.role;
    this.getTemplates();
  },
  methods: {
    getTemplates() {
      this.isLoading = true;
      emailTemplate
        .paginate(this.search, this.page)
        .then(response => {
          this.email_templates = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    __edit(id) {
      this.$router.push({
        name: "email-template-update",
        params: { id: id }
      });
    },
    searchEmailTemplate() {
      this.getTemplates();
    },
    clone(item) {
      this.cloneLoading = true;
      this.email_template = item;
      this.email_template.role = this.role;
      emailTemplate.clone(this.email_template).then(response => {
        if (response.data.status === "OK") {
          this.$snotify.success("Email Template Customize Successfully");
          this.cloneLoading = false;
          this.getTemplates();
          this.closeCloneForm();
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep {
  .v-application ul,
  .v-application ol {
    padding-left: 0 !important;
  }
}
</style>
